import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import logoFacebook from "../images/facebook.svg"
import logoInstagram from "../images/instagram.svg"
import "./footer.css"

const Footer = React.forwardRef((props, ref) => {

  const data = useStaticQuery(graphql`
    query {
      ljdb: file(relativePath: { eq: "ljdb-no-text.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return(
    <footer ref={ref} className="footer">
      <div className="footer-wrap">
        <article className="footer__section contact">
          <Img fixed={data.ljdb.childImageSharp.fixed} alt="Les Jardins de Basile"/>
          <p>Paysagiste - Entretien des espaces verts, clôtures, aménagements extérieurs</p>
        </article>
        <article className="footer__section contact">
          <h1 className="footer__title">Contact</h1>
          <address>
            <ul className="contact__address">
              <li className="contact__address-line">Rte de la Riaz 6</li>
              <li className="contact__address-line">1580 Donatyre</li>
            </ul>
            <ul className="contact__address-email">
              <li className="contact__address-line" ><a className="footer__link" href="mailto:basile.bigler@gmail.com">basile.bigler@gmail.com</a></li>
            </ul>
            <ul className="contact__address-phone">
              <li className="contact__address-line contact__address-phone-number"><a className="footer__link" href="tel:+41795162891">+41 79 516 28 91</a></li>
            </ul>
          </address>
        </article>
        <article className="footer__section social-networks">
          <h1 className="footer__title">Suivez-nous</h1>
          <a className="footer__social-link" href="https://www.instagram.com/lesjardinsdebasile"><img src={logoInstagram} alt="instagram"/></a>
          <a className="footer__social-link" href="https://www.facebook.com/lesjardinsdebasile/"><img src={logoFacebook} alt="facebook"/></a>
        </article>
        <article className="footer__copyright footer-item">
          <p className="footer__copyright-info">© Copyright 2019. Les Jardins de Basile</p>
          <p className="footer__dev-info">Design et développement par <a className="footer__link" href="https://adrienbigler.ch">Adrien Bigler</a></p>
        </article>
      </div>
    </footer>
  )
})



export default Footer
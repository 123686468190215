import React from "react"
import "./services.css"

const Services = React.forwardRef((props, ref) => {

  return(
    <article className="services" ref={ref}>
      <h1 className="services__heading">Services</h1>
      <section className="services__services">
        <article className="service">
          <h1 className="service__heading">Conseils pour votre projet paysager</h1>
          <p>Rien n'est trop beau pour votre jardin! Avec vous, nous étudions votre projet de jardin paysager. Selon vos envies, nous vous conseillons sur les travaux à réaliser.</p>
        </article>
        <article className="service">
          <h1 className="service__heading">Aménagements extérieurs</h1>
          <p>Nous mettons notre savoir-faire au service des particuliers, des professionnels et des collectivités pour concevoir, réaliser et entretenir tous types d'espaces extérieurs.</p>
        </article>
        <article className="service">
          <h1 className="service__heading">Entretiens des espaces verts & clôtures</h1>
          <p>Nous avons les compétences pour vous proposer un espace vert agréable en mixant les ambiances végétales, minérales et décoratives.</p>
        </article>
      </section>
    </article>
  )
})



export default Services
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./intro.css"

const Intro = () => {

  const data = useStaticQuery(graphql`
    query {
      ljdb: file(relativePath: { eq: "ljdb-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return(
    <article className="intro">
      <div className="intro__content">
        <h1 className="intro__heading"><Img className="intro__img" fluid={data.ljdb.childImageSharp.fluid} alt="Les Jardins de Basile"/></h1>
      </div>
    </article>
  )
}



export default Intro
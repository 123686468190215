import React, {useRef} from "react"
import SEO from "../components/seo"
import Header from "../components/header"
import Intro from "../components/intro"
import Services from "../components/services"
import Footer from "../components/footer"



const IndexPage = () => {

  
  const scrollToRef = (ref) => window.scrollTo({left:0, top:ref.current.offsetTop,behavior:'smooth'})
  const contactRef = useRef(null)
  const servicesRef = useRef(null)
  const scrollToContact = () => scrollToRef(contactRef)
  const scrollToServices = () => scrollToRef(servicesRef)

  return(
    <React.Fragment>
      <SEO title="Home" />
      <Header scrollToContact={scrollToContact} scrollToServices={scrollToServices}/>
      <Intro/>
      <Services ref={servicesRef}/>
      <Footer ref={contactRef}/>
    </React.Fragment>
  )
}
export default IndexPage
